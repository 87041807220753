import React from 'react';
import "yet-another-react-lightbox/styles.css";
import './styles.scss';
import Swal from 'sweetalert2';

import { findFavouritesOfShooting } from '../../utils/findFavouritesOfShooting';

export const Favourites = ({
  setFavourite,
  shooting,
  validateFavourites,
  userId,
  favouritesSelected,
  user,

}) => {

  const handleSetFavourite = (evt) => {
    setFavourite(evt.target.id, shooting.id);
  };

  let favourites = [];
  if(user.isClient && shooting) {
    favourites = findFavouritesOfShooting(shooting);
  }

  const handleValidateFavourites = () => {
    if(findFavouritesOfShooting(shooting).length === shooting.numberOfPictures) {
      Swal.fire({
        text: 'Cette action va vous permettre de télécharger vos photos, vous ne pourrez donc plus modifier votre sélection, êtes-vous sûr(e) ?',
        showDenyButton: true,
        confirmButtonText: `Valider`,
        denyButtonText: `Annuler`,
        icon: 'question',
      }).then((result) => {
        if (result.isConfirmed) {
          validateFavourites(shooting.id, userId);

          Swal.fire({
            title: 'Vos favorites ont bien été validées ! Vous allez pouvoir les télécharger tout de suite.',
            icon: 'success',
            confirmButtonText: 'Ok',
          }).then(() => {
            window.location.reload();
          })
        }
      })
    } else {
      Swal.fire({
        text: `Vous avez sélectionné ${findFavouritesOfShooting(shooting).length - shooting.numberOfPictures} photos en plus que le nombre prévu dans votre formule. Pas de panique ! N'hésitez pas à prendre contact avec moi afin d'augmenter le nombre de photos de votre forfait.`,
        icon: 'warning',
        confirmButtonText: 'Ok',
      })
    }
  }
  


  return (
    <div>
      {user.isClient && favourites.length > 0 && !favouritesSelected &&
        <div className='gallery__container__favourites'>
            <h3 className='text-center mb-3 text-black'> Je les veux ! </h3>
            {favourites.map((favourite, index) => {
              return (
                <div className='gallery__container__favourites__favourite' key={favourite.id}>
                  <span> {index +1 }. </span>
                  <img className='gallery__container__favourites__favourite__photo' src={favourite.secureUrl} alt={favourite.id} />
                  <div>
                    <p> { favourite.originalName }</p>
                    <button className='mySmallButton' onClick={handleSetFavourite} id={favourite.id}> Retirer des favorites </button>
                  </div> 
                </div>
                )
              })
            }
            {user.isClient && findFavouritesOfShooting(shooting).length >= shooting.numberOfPictures  && 
              <button className='myButton d-block mx-auto my-3' onClick={handleValidateFavourites}> Valider mes favorites </button>
            }
        </div>
      }
    </div>
      
        
          
      
    
  );
};

Favourites.propTypes = {
};
