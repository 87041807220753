import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import './styles.scss';

import Home from './../../containers/Home';
import Contact from './../../pages/Contact';
import Login from './../../containers/Login';
import ShootingList from '../../containers/Shooting/list';
import ShootingShow from '../../containers/Shooting/show';
import Account from '../../containers/Account';
import ChangeTemporaryPassword from '../../containers/ChangeTemporaryPassword';
import { About } from './../../pages/About';
import Clients from '../../containers/Clients';
import ChangeForgotPassword from '../../containers/ChangeForgotPassword';
//import Theme from '../../containers/Theme';
//import Themes from '../../containers/Themes';
import NavBar from '../../containers/NavBar';
import { Infos } from './../../pages/Infos';


//import _ from 'lodash';

export function App({
  isLogged,
  //themes,
  user,
}) {
    
  return (
    <div className="app">
      <NavBar />
      <div className='app__body'>
        <Routes>
          <Route path="/" element={ <Home />} />
          <Route path='login' element={<Login />} />
          <Route path='reinitialiser-mot-de-passe/:token/:email' element={<ChangeForgotPassword />} />
          <Route path='contact' element={<Contact />} />
          {/* <Route path='seances' element={<Themes />} />
          {themes.map((theme, index) => (
            <Route key={index} path={`/seance/${_.kebabCase(theme.name)}`} element={<Theme theme={theme} />} />
          ))} */}

          <Route path='infos' element={<Infos />} />
          <Route path='votre-photographe' element={<About />} />

          {isLogged && <Route path='galeries' element={<ShootingList />} />}
          {isLogged && <Route path='galerie/:id' element={<ShootingShow />} />}
          {isLogged && <Route path='mon-compte/:id' element={<Account />} />}
          
          {isLogged && !user.isClient && <Route path='clients' element={<Clients />} />}
          {isLogged && user.isClient && user.firstConnect && <Route path='temp' element={<ChangeTemporaryPassword />} />}


          <Route path='*' element={<Navigate to="/" replace />} />

        </Routes>
      </div>
    </div>
  );
}

App.propTypes = {
};

