  import {
    SAVE_ALL_CLIENTS_OF_PHOTOGRAPHER,
    SAVE_USER
  } from '../actions/user';

  import {
    LOGOUT,
  } from '../actions/authentification';

  export const initialState = {
    clients: [],
    isClient: true,
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    SIRET: '',
    address: '',
    postalCode: '',
    city: '',
    phoneNumber: '',
    country: '',
    firstConnect: true,
  };

  const reducer = (state = initialState, action = {}) => {
    switch (action.type) {

        case SAVE_ALL_CLIENTS_OF_PHOTOGRAPHER:
          return {
            ...state,
            clients: action.clients,
          };

        case SAVE_USER:
          const { isClient, firstName, lastName, email, SIRET, address, postalCode, city, phoneNumber, country, firstConnect } = action.payload.user;
          return {
            ...state,
            isClient,
            firstName,
            lastName,
            email,
            SIRET,
            address,
            postalCode,
            city,
            phoneNumber,
            country,
            firstConnect
          };

      case LOGOUT:
        return initialState;


      default:
        return state;
    }
  };

  export default reducer;
