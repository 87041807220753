import React from 'react';
// import PropTypes from 'prop-types';
import './styles.scss';

import image from './../../assets/images/Laura/LauraRobe.png';


export const About = () => {

  return (
    <div className='about'>
      <div className='about__title'>
        <p>Coucou ! </p>
        <p> Moi c’est Laura</p>
      </div>


      <img src={image} alt="laura huisman" className='about__image' />

      <div className='about__text' > 

        <div className='about__text__part'>
          <p>Maman de mes 2 amours de ma vie : Milan et Lily, et photographe professionnelle depuis 2017, spécialisée dans la famille, la maternité et la naissance.</p>

          <p>La photo, j’en fais depuis très longtemps.</p>

          <p>Depuis l’adolescence, j’ai toujours eu mon appareil photo avec moi. J’adorais prendre en photo mes copines, mes animaux, ma famille et mes voyages.</p>

          <p>J’ai toujours eu une préférence pour le portrait, et après plusieurs superbes stages, je me suis spécialisée dans la photo de grossesse, nouveau-né et famille.</p>

          <p>Je ne cesse d'approfondir mes connaissances, pour moi, il est très important d'en apprendre toujours plus. </p>
        </div>

        <div className='about__text__part'>
          <p> C'est pour cela que j'ai suivi d'autres stages avec des personnes mondialement reconnues dans le domaine. </p>
          <p>J'adore capturer les jolis moments de la vie.</p>
          <p>Étant moi-même jeune maman, je suis consciente de l'importance d'immortaliser les moments exceptionnels de la vie comme la grossesse, la naissance et surtout l'évolution de vos loulous !</p>

          <p>Ma douceur et ma patience font partie de mes atouts pour réaliser vos plus beaux souvenirs.</p>

          <p>Si mon travail vous plaît et que vous avez envie d'une séance photo pour immortaliser un moment important de votre vie ou tout simplement pour vous faire plaisir, n'hésitez pas à me contacter !</p>

          <p>A bientôt.</p>
        </div>
        

       
      </div>
          
    </div>
  );
};

About.propTypes = {
};


