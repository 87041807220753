import React from 'react';
import { Navigate } from 'react-router-dom';

import './styles.scss';
import ChangePassword from '../../containers/ChangePassword';

export const ChangeTemporaryPassword = ({
  firstConnect,
}) => {
  
  return (

    <div className='changeTemporaryPassword'>
      
      { !firstConnect && ( <Navigate to={`/galeries`} /> )}

      <div className='changeTemporaryPassword__description'> 
        <p>C'est votre première connexion, bienvenue! </p>
        <p> Merci de modifier le mot de passe qui vous a été proposé afin de sécuriser votre compte. </p> 
      </div>

      <ChangePassword />

    </div>
    

  );
};

ChangeTemporaryPassword.propTypes = {
};
