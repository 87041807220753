import {
    SAVE_USER,
    LOGOUT,
    SHOW_ERROR_LOGIN_MESSAGE,
    FORGOT_ERROR,
    FORGOT_SUCCESS,
    SET_SUCCESS_CHANGE_PW,
  } from '../actions/authentification';

  export const initialState = {
    isLogged: false,
    showErrorMessage: false,
    errorMessage: '',
    messageForgot: false,
    successMessage: false,
    id: null,
    successChangePw: false,
  };

  const reducer = (state = initialState, action = {}) => {
    switch (action.type) {

      case SAVE_USER: {
        const { id } = action.payload.user;
        return {
            ...state,
            id,
            isLogged: true,
            showErrorMessage: false,
        };
      }

      case LOGOUT: {
        return {
          ...initialState
        }
      }

      case SHOW_ERROR_LOGIN_MESSAGE: {
        return {
          ...state,
          showErrorMessage: true,
          errorMessage: action.message,
        }
      }

      case FORGOT_ERROR: {
        return {
          ...state,
          messageForgot: false,
          errorMessage: true,
          successMessage: false,
          email: '',
        };
      }

      case FORGOT_SUCCESS: {
        return {
          ...state,
          messageForgot: false,
          errorMessage: false,
          successMessage: true,
          email: '',
        };
      }

      case SET_SUCCESS_CHANGE_PW: {
        return {
          ...state,
          successChangePw: !state.successChangePw,
        };
      }

      default:
        return state;
    }
  };

  export default reducer;
