import React from 'react';
//import PropTypes from 'prop-types';

import './styles.scss';

export const FirstPart = () => {

  return (
  
    <div className='firstPart'>
      <div className='firstPart__bigTitle customTitle'> Reflet de vos souvenirs les plus précieux. </div>
      <h1 className='firstPart__subTitle customTitle'> Photographe lifestyle dans le Gard</h1>
    </div>
  
  )};

FirstPart.propTypes = {
};

