import React, { useState } from 'react';
import Swal from 'sweetalert2'

import './styles.scss';


export const ChangeForgotPassword = ({
  updateUsersPassword,
  error,
  successChangePw,
  setSuccessChangePw,
  clearError,

}) => {

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // get the token from the url
  const token = window.location.pathname.split('/')[2];
 
  //get the email from the url
  const encodedEmail = window.location.pathname.split('/')[3];
  const email = decodeURIComponent(encodedEmail);


  if (successChangePw) {
    setTimeout(() => {
      setSuccessChangePw(false);
      setNewPassword('');
      setConfirmPassword('');
    }, 3000);
  }
  

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    
    if (newPassword === confirmPassword) {
      await updateUsersPassword(newPassword, token, email);
    } 
    if (newPassword !== confirmPassword) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Les mots de passe ne correspondent pas !',
      })
    }
  }
// clear error after 5 seconds
if(error) {
  setTimeout(() => {
    clearError();
  }, 5000);
}
  return (

    <div className='changePassword'>
      <p className='changePassword__title'> Modifier mon mot de passe </p>
      
      { error && (
        <div className="alert alert-danger changePassword__alert">
          <div role="alert">
            { error }
          </div>
      </div>
      )}

      { successChangePw && (
        <div className="alert alert-success changePassword__alert">
          <div role="alert">
            Votre mot de passe a bien été modifié !
          </div>
        </div>
        )}

      <form autoComplete="off" onSubmit={handleSubmit} className="changePassword__form">
        <input
          type="password"
          placeholder="Nouveau mot de passe"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          className="changePassword__form__input"
        />

        <input
          type="password"
          placeholder="Confirmer nouveau mot de passe"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          className="changePassword__form__input"
        />

        <button type="submit" className="myButton mx-auto mt-3"> Valider </button>
      </form>
    </div>

  )};
