import React, { useState } from 'react';
import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";


import './styles.scss';
import image1 from './../../../assets/images/landscape/jeux.jpg';
import image2 from './../../../assets/images/portrait/bisouMaman.jpg';
import image3 from './../../../assets/images/landscape/Lilypoupee.jpg';
import image4 from './../../../assets/images/landscape/ventreRondJu.jpg';



export const Pictures = () => {
  

  const images = [
    {
       src: image1,
       original: image1,
       width: 1280,
       height: 853,
       alt: "",
    },
    {
      src: image2,
      original: image2,
      width: 853,
      height: 1280,
      alt: "",
    },
    {
       src: image3,
        original: image3,
       width: 1280,
       height: 901,
       alt: "",
    },
   {
      src: image4,
      original: image4,
      width: 1280,
      height: 853,
      alt: "",
    },
 ];
 
 const [index, setIndex] = useState(-1);

 const handleClick = (index, item) => setIndex(index);

 const slides = images.map(({ original, width, height }) => ({
    src: original,
    width,
    height,
  }));

  return (
    <div className='pictures'>
      <Gallery 
        images={images}
        rowHeight={400}
        margin={4}
        enableImageSelection={false}
        onClick={handleClick} 
      />
      <Lightbox
        slides={slides}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
      />
      
    </div>
  );
};

Pictures.propTypes = {};
