export const UPDATE_USERS_PASSWORD = 'UPDATE_USERS_PASSWORD';
export const ADD_NEW_USER = 'ADD_NEW_USER';
export const FETCH_CLIENTS_OF_PHOTOGRAPHER = 'FETCH_CLIENTS_OF_PHOTOGRAPHER';
export const SAVE_ALL_CLIENTS_OF_PHOTOGRAPHER = 'SAVE_ALL_CLIENTS_OF_PHOTOGRAPHER';
export const UPDATE_USER_FORGOT_PASSWORD = 'UPDATE_USER_FORGOT_PASSWORD';
export const SAVE_USER = 'SAVE_USER';
export const UPDATE_USER = 'UPDATE_USER';


export const updateUsersPassword = (userId, oldPassword, newPassword) => ({
    type: 'UPDATE_USERS_PASSWORD',
    userId,
    oldPassword,
    newPassword
});

export const fetchClientsOfPhotographer = (photographerId) =>({
  photographerId,
  type: 'FETCH_CLIENTS_OF_PHOTOGRAPHER'
});

export const saveAllClientsOfPhotographer = (clients) => ({
  clients,
  type: 'SAVE_ALL_CLIENTS_OF_PHOTOGRAPHER'
})

export const updateUserForgotPassword = (newPassword, token, email) => ({
  type: 'UPDATE_USER_FORGOT_PASSWORD',
  newPassword,
  token,
  email
});

export const saveUser = (payload) => ({
  type: SAVE_USER,
  payload,
});

export const updateUser = (userId, data) => ({
  type: UPDATE_USER,
  userId,
  data
});
