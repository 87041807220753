import {
  LOGIN,
  saveUser,
  showErrorLoginMessage,
  createForgotErrorAction,
  createForgotAction,
  FORGOT_PASSWORD,
  SIGNUP
} from './../actions/authentification';
import { fetchShootingsByUser } from './../actions/shooting';
//import { FetchThemesByUser } from './../actions/theme';
import { fetchClientsOfPhotographer } from './../actions/user';
import { setErrorMessage } from './../actions/error';
import { setSuccessMessage } from './../actions/success';

import api from './utils/api';

const authmiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case LOGIN: {
      api({
        method: 'POST',
        url: 'auth/login',
        data: {
          username: action.email,
          password: action.password,
        },
      })
        .then((response) => {
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('userId', response.data.user.id);
          localStorage.setItem('refreshToken', response.data.refreshToken);
          store.dispatch(saveUser(response.data));
          store.dispatch(fetchShootingsByUser(response.data.user.id));
          //store.dispatch(FetchThemesByUser()); 
        })
        .catch((error) => {
          console.log(error);
          const message = 'Erreur d\'identifiants';
          store.dispatch(showErrorLoginMessage(message));
        });
      break;
    }

    case SIGNUP: {
      const state = store.getState();
      api({
        method: 'POST',
        url: `auth/signup`,
        data: {
          ...action.newClient,
          userId: state.auth.id,
        }
      })
        .then((response) => {
          store.dispatch(setSuccessMessage(response.data.message));
          store.dispatch(fetchClientsOfPhotographer());
        })
        .catch((error) => {
          store.dispatch(setErrorMessage(error.response.data.message));
          return Promise.reject(error);
        });
     
      break; 
    }

    case FORGOT_PASSWORD: {
      api({
        method: 'POST',
        url: '/auth/forgot-password',
        data: {
          email: action.forgotEmail,
          photographerId: process.env.REACT_APP_PHOTOGRAPHER_ID,
        },
      })
        .then((response) => {
          if (response.data === true ) {
            store.dispatch(createForgotAction());
          } else {
            store.dispatch(createForgotErrorAction());
          }
        })
        .catch((error) => {
          console.log(error);
        });
      break;
    }

    default: {
      next(action);
      break;
    }
  }

};

export default authmiddleware;
