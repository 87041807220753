import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import Swal from 'sweetalert2'
import DatePicker from "react-datepicker";
import './styles.scss';
import 'react-datepicker/dist/react-datepicker.css';


export const AddGallery = ({
  isLogged,
  addNewShooting,
  //themes,
  fetchClients,
  user,
}) => {

  //const [theme, setTheme] = useState({ rates: [] });
  const [startDate, setStartDate] = useState(new Date());
  const [newShooting, setNewShooting] = useState({ date:'', nameOfGallery:'', clientId:'', themeId:'', numberOfPictures:'' })
  const [showForm, setShowForm] = useState(false); 
  
  useEffect(() => {
    if (isLogged && !user.isClient) {
      fetchClients();
    }
  }, [
    fetchClients,
    isLogged,
    user.isClient,
  ]);

  if(isLogged & user.firstConnect) {
    return <Navigate to="/temp" />;
  }
  

  const handleNewShooting = (evt) => {
    if (newShooting.date === '') {
      newShooting.date = startDate;
    }
    try {
      addNewShooting(newShooting);
    } catch (error) {
      console.log(error);
    } finally {
      setNewShooting({ date:'', nameOfGallery:'', clientId:'', themeId:'', numberOfPictures:'' })
      setShowForm(!showForm);
    }
  };

  const toggleForm = () => {
    const clientOptions = user.clients.map(client => 
      `<option value="${client.id}">${client.firstName} ${client.lastName}</option>`
    ).join('');

    /* const themeOptions = themes.map(theme =>
      `<option value="${theme.id}">${theme.name}</option>`
    ).join(''); */

    Swal.fire({
      title: "Créer une nouvelle galerie",
      html: `
        <input id="name-gallery" className="swal2-input" placeholder="Nom de la galerie">
        <select id="client-select" className="swal2-input">
          <option value="" disabled selected>Sélectionnez un client</option>
          ${clientOptions}
        </select>
        <input id="date" className="swal2-input" placeholder="Date de la séance" type="date">
        <input id="number-of-pictures" className="swal2-input" placeholder="Nombre de photos" type="number">
      `,
      focusConfirm: false,
      preConfirm: () => {
        const nameGallery = document.getElementById("name-gallery").value;
        const clientSelect = document.getElementById("client-select").value;
        //const themeSelect = document.getElementById("theme-select").value;
        const date = document.getElementById("date").value;
        const numberOfPictures = document.getElementById("number-of-pictures").value;
  
        if (!nameGallery || !clientSelect || !date || !numberOfPictures) {
          Swal.showValidationMessage("Tous les champs doivent être remplis");
          return null;
        }
  
        return { nameGallery, clientSelect, date, numberOfPictures };
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        const formValues = result.value;
        const newShooting = {
          nameOfGallery: formValues.nameGallery,
          clientId: parseInt(formValues.clientSelect, 10),
          //themeId: parseInt(formValues.themeSelect, 10),
          date: formValues.date,
          numberOfPictures: formValues.numberOfPictures
        };
    
        try {
          await addNewShooting(newShooting);
          Swal.fire(`Nouvelle galerie "${formValues.nameGallery}" créée avec succès!`);
        } catch (error) {
          console.log(error);
          Swal.fire('Erreur', 'Une erreur est survenue lors de la création de la galerie', 'error');
        } finally {
          setNewShooting({ date:'', nameOfGallery:'', clientId:'', numberOfPictures:'' });
        }
      }
    });
  };

  return (
    <div className='newGallery'>
      {!showForm && (
        <button onClick={toggleForm} className="newGallery__button">
          <i className="bi bi-plus" style={{ fontSize: "2rem" }}></i>
          <span className="button-text">Ajouter galerie</span>
        </button>
      )}

      {showForm && (
        <>
          <div className='newGallery__form__container'>
            <div className='newGallery__form__container__element'>
              <input
                type='text'
                placeholder="Nom de la galerie"
                onChange={(e) =>
                  setNewShooting({
                    ...newShooting,
                    nameOfGallery: e.target.value,
                  })
                }
                className='newGallery__form__container__element__input'
              />
            </div>

            <div className='newGallery__form__container__element'>
              <select 
                onChange={() =>
                  setNewShooting ({
                    ...newShooting,
                    clientId: parseInt(document.getElementById('client-select').value, 10)
                    })
                  } 
                id="client-select" 
                className='newGallery__form__container__element__select'
              >

                <option> Client </option>

                  { user.clients.map((client) =>
                    <option key={client.id} value={client.id}>{client.firstName} {client.lastName}</option>
                  )}
              </select>
            </div>

          </div>

          <div className='newGallery__form__container'>

            {/* <div className='newGallery__form__container__element'>
              <select 
                onChange={() => {
                  setTheme({
                    ...theme,
                    id: parseInt(document.getElementById('theme-select').value, 10),
                    rates: themes.find((theme) => theme.id === parseInt(document.getElementById('theme-select').value, 10)).rates
                  });

                  setNewShooting({
                    ...newShooting,
                    themeId: parseInt(document.getElementById('theme-select').value, 10)
                  })
                }}
                placeholder='Choisir un thème' 
                id="theme-select"
                className='newGallery__form__container__element__select'
              >

              <option> Thème </option>

              {themes.map((theme) =>
                <option key={theme.id} value={theme.id}> {theme.name} </option>
                ) }
              </select>

              
            </div> */}

            <div className='newGallery__form__container__element'>
              <DatePicker
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  setNewShooting({
                    ...newShooting,
                    date: date,
                    })
                  }
                }
                dateFormat="dd/MM/yyyy"
                className='newGallery__form__container__element__select'
              />
            </div>

            <div className='newGallery__form__container__element'>
              <input
                type='number'
                placeholder="Nombre de photos"
                onChange={(e) =>
                  setNewShooting({
                    ...newShooting,
                    numberOfPictures: e.target.value,
                  })
                }
                className='newGallery__form__container__element__input'
              />
            </div>

          </div>

          <div className='newClient__form__buttons'>
            <button onClick={handleNewShooting} className='myButton m-3'>
              Valider
            </button>
            <button type='button' onClick={toggleForm} className='myButton bg-danger m-3'>
              Annuler
            </button>
          </div>
        </>
      )}

    </div>
  );
};

AddGallery.propTypes = {
};
