import { connect } from 'react-redux';
import { ShootingList } from '../../pages/Shooting/List';
import { deleteShooting, addNewShooting, fetchShootingsByUser } from '../../store/actions/shooting';


const mapStateToProps = (state, ownProps) => ({
  isLogged: state.auth.isLogged,
  shootings: state.shooting.shootings,
  user: state.user,

});

const mapDispatchToProps = (dispatch, ownProps) => ({
  
  deleteShooting: (shootingId) => {
    dispatch(deleteShooting(shootingId))
  },

  addNewShooting : (newShooting) => {
    dispatch(addNewShooting(newShooting));
  },

  fetchShootingsByUser: () => {
    dispatch(fetchShootingsByUser())
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShootingList);
