import React, { useEffect, useState } from 'react';
import './styles.scss';

export const Clients = ({
  addNewUser,
  clients,
  fetchClients,
  error,
  closeError,
  success,
  closeSuccess,
  updateUser,
}) => {
  const [showAddForm, setShowAddForm] = useState(false);
  const [newClient, setNewClient] = useState({
    newClientFirstName: '',
    newClientLastName: '',
    newClientEmail: '',
    newClientPassword: '',
    newClientAddress: '',
    newClientPostalCode: '',
    newClientCity: '',
    newClientCountry: '',
    newClientPhoneNumber: '',
  });
  const [showEditForm, setShowEditForm] = useState(false);
  const [editedClientId, setEditedClientId] = useState(null);
  const [editedClient, setEditedClient] = useState({});

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  const handleNewClient = async (evt) => {
    evt.preventDefault();
    await addNewUser(newClient);
    setNewClient({
      newClientFirstName: '',
      newClientLastName: '',
      newClientEmail: '',
      newClientPassword: '',
      newClientAddress: '',
      newClientPostalCode: '',
      newClientCity: '',
      newClientCountry: '',
      newClientPhoneNumber: '',
    });
    setShowAddForm(false);
  };

  const handleGenerateRandomPassword = (evt) => {
    evt.preventDefault();
    const randomPassword = Math.random().toString(36).slice(-8);
    setNewClient({
      ...newClient,
      newClientPassword: randomPassword,
    });
  };

  const toggleAddForm = () => {
    setShowAddForm(!showAddForm);
    setEditedClientId(null);
    setShowEditForm(false);
  };

  const toggleEditForm = (clientId) => {
    const client = clients.find((client) => client.id === clientId);
    setEditedClient(client);
    setShowEditForm(true);
    setEditedClientId(clientId);
    setShowAddForm(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewClient({
      ...newClient,
      [name]: value,
    });
  };

  const handleCloseError = () => {
    closeError();
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditedClient({
      ...editedClient,
      [name]: value,
    });
  };

  const handleEditClient = async (evt) => {
    evt.preventDefault();
    const updates = {};
    const client = clients.find(client => client.id === editedClientId);
    for (let key in editedClient) {
      if (editedClient[key] !== client[key]) {
        updates[key] = editedClient[key];
      }
    }
    await updateUser(editedClientId, updates);
    setEditedClientId(null);
    setShowEditForm(false);
  };

  return (
    <div className='newClient'>
      {!showAddForm && (
        <button onClick={toggleAddForm} className='myButton m-auto my-2'>
          <i className="bi bi-plus"></i>
        </button>
      )}

      {error && (
        <div className='alert alert-danger newClient__form d-flex justify-content-between' role='alert'>
          {error} <button onClick={handleCloseError} >X</button>
        </div>
      )}
      {success && (
        <div className='alert alert-success newClient__form d-flex justify-content-between' role='alert'>
          {success} <button onClick={closeSuccess} >X</button>
        </div>
      )}

      {showAddForm && (
        <form autoComplete='off' method='POST' className='newClient__form' onSubmit={handleNewClient}>
          <div className="container">
            <div className="row">
              <div className="col">
                <input
                  type="text"
                  name="newClientFirstName"
                  placeholder="Prénom"
                  className="newClient__form__input"
                  value={newClient.newClientFirstName}
                  onChange={handleChange}
                />
              </div>
              <div className="col">
                <input
                  type="text"
                  name="newClientLastName"
                  placeholder="Nom"
                  className="newClient__form__input"
                  value={newClient.newClientLastName}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <input
                  type="text"
                  name="newClientEmail"
                  placeholder="Email"
                  className="newClient__form__input"
                  value={newClient.newClientEmail}
                  onChange={handleChange}
                />
              </div>
              <div className="col d-flex">
                <input
                  type="text"
                  name="newClientPassword"
                  placeholder="Password"
                  className="newClient__form__input"
                  value={newClient.newClientPassword}
                  onChange={handleChange}
                />
                <button onClick={handleGenerateRandomPassword} className="mySmallButton ms-2">
                  Générer un mot de passe aléatoire
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <input
                  type="text"
                  name="newClientAddress"
                  placeholder="Adresse"
                  className="newClient__form__input"
                  value={newClient.newClientAddress}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <input
                  type="text"
                  name="newClientPostalCode"
                  placeholder="Code postal"
                  className="newClient__form__input"
                  value={newClient.newClientPostalCode}
                  onChange={handleChange}
                />
              </div>
              <div className="col">
                <input
                  type="text"
                  name="newClientCity"
                  placeholder="Ville"
                  className="newClient__form__input"
                  value={newClient.newClientCity}
                  onChange={handleChange}
                />
              </div>
              <div className="col">
                <input
                  type="text"
                  name="newClientCountry"
                  placeholder="Pays"
                  className="newClient__form__input"
                  value={newClient.newClientCountry}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <input
                  type="text"
                  name="newClientPhoneNumber"
                  placeholder="Numéro de téléphone"
                  className="newClient__form__input"
                  value={newClient.newClientPhoneNumber}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className='newClient__form__buttons'>
            <button type='submit' className='myButton m-3'>
              Valider
            </button>
            <button type='button' onClick={toggleAddForm} className='myButton bg-danger m-3'>
              Annuler
            </button>
          </div>
        </form>
      )}

      <table className="table table-striped w-75 m-auto bg-light mt-4">
        <thead>
          <tr>
            <th scope="col">Nom</th>
            <th scope="col">Adresse</th>
            <th scope="col">Email</th>
            <th scope="col">Numéro de téléphone</th>
            <th scope="col">Mot de passe provisoire</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {clients && clients.sort((a, b) => {
            if (a.createdAt < b.createdAt) return 1;
            if (a.createdAt > b.createdAt) return -1;
            return 0;
          }).map((client) => (
            <tr key={client.id}>
              <th scope="row">
                {showEditForm && editedClientId === client.id ? (
                  <>
                    <input
                      type="text"
                      name="firstName"
                      placeholder="Prénom"
                      className="newClient__form__input"
                      value={editedClient.firstName}
                      onChange={handleEditChange}
                    />
                    <input
                      type="text"
                      name="lastName"
                      placeholder="Nom"
                      className="newClient__form__input"
                      value={editedClient.lastName}
                      onChange={handleEditChange}
                    />
                  </>
                ) : client.firstName + ' ' + client.lastName}
              </th>
              <td>
 
                { showEditForm && editedClientId === client.id ? (
                  <>
                    <input
                      type="text"
                      name="address"
                      placeholder="Adresse"
                      className="newClient__form__input"
                      value={editedClient.address}
                      onChange={handleEditChange}
                    />
                    <input
                      type="text"
                      name="postalCode"
                      placeholder="Code postal"
                      className="newClient__form__input"
                      value={editedClient.postalCode}
                      onChange={handleEditChange}
                    />
                    <input
                      type="text"
                      name="city"
                      placeholder="Ville"
                      className="newClient__form__input"
                      value={editedClient.city}
                      onChange={handleEditChange}
                    />
                  </>
                ) : client.address + ' - ' + client.postalCode + ' ' + client.city }
              </td>
              <td> 
                { showEditForm && editedClientId === client.id ? (
                  <input
                    type="text"
                    name="email"
                    placeholder="Email"
                    className="newClient__form__input"
                    value={editedClient.email}
                    onChange={handleEditChange}
                  />
                ) : client.email }
              </td>
              <td> 
                { showEditForm && editedClientId === client.id ? (
                  <input
                    type="text"
                    name="phoneNumber"
                    placeholder="Numéro de téléphone"
                    className="newClient__form__input"
                    value={editedClient.phoneNumber}
                    onChange={handleEditChange}
                  />
                ) : client.phoneNumber }
              </td>
              <td>
                { showEditForm && editedClientId === client.id && client.firstConnect ? (
                  <input
                    type="text"
                    name="password"
                    placeholder="Password"
                    className="newClient__form__input"
                    value={editedClient.password}
                    onChange={handleEditChange}
                  />
                ) : client.password }
              </td>
              <td>
                { showEditForm && editedClientId === client.id ? 
                  (
                    <>
                      <button onClick={handleEditClient}>
                        Valider
                      </button>
                      <button type='button' onClick={toggleEditForm}>
                        Annuler
                      </button>
                    </>
                  ) : (
                    <>
                      <button onClick={() => toggleEditForm(client.id)}>
                        <i className="bi bi-pencil"></i>
                      </button>
                    </>
                  )
                }
              </td>
            </tr>
            
          ))}
        </tbody>
      </table>
    </div>
  );
};

Clients.propTypes = {};

