import { connect } from 'react-redux';
import { AddGallery } from '../../components/AddGallery';

import { addNewShooting, setShootingId } from '../../store/actions/shooting';
import { fetchClientsOfPhotographer } from '../../store/actions/user';


const mapStateToProps = (state, ownProps) => ({
    isLogged: state.auth.isLogged,
    shootings: state.shooting.shootings,
    shooting: state.shooting.shooting,
    id: state.auth.id,
   //themes: state.theme.themes,
    user: state.user,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    addNewShooting : (newShooting) => {
        dispatch(addNewShooting(newShooting));
      },
      
      setShootingId: (shootingId) => {
        dispatch(setShootingId(shootingId))
      },
    
      fetchClients: () => {
        const photographerId = localStorage.getItem('photographerId');
        dispatch(fetchClientsOfPhotographer(photographerId))
      },
    
});


export default connect(mapStateToProps, mapDispatchToProps)(AddGallery);
