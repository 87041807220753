import { connect } from 'react-redux';
import { NavBar } from '../../components/NavBar';

//import { FetchThemesByUser } from '../../store/actions/theme';
import { logout } from './../../store/actions/authentification'


const mapStateToProps = (state, ownProps) => ({
    //themes: state.theme.themes,
    isLogged: state.auth.isLogged,
    id: state.auth.id,
    user: state.user
});

const mapDispatchToProps = (dispatch, ownProps) => ({

    /* FetchThemesByUser: () => {
        dispatch(FetchThemesByUser());
    }, */

    logout: () => {
        dispatch(logout())
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
    }
});


export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
