import React from 'react';
// import PropTypes from 'prop-types';
import './styles.scss';

import image2 from './../../assets/images/portrait/JustineDeboutFenetre.jpg';


export const Infos = () => {

  return (
    <div className='infos'>
        <div className='infos__text'>
          <p className='infos__text__intro'> Je vous propose une séance lifestyle, où chaque moment partagé devient un souvenir intemporel, sans artifice ni pose figée. </p>
          <p>Je recherche des expressions naturelles et spontanées.</p>
          <p>Que vous soyez en famille, en pleine découverte de la naissance d’un enfant, ou en attendant la venue d’un nouveau membre, je vous rejoins dans votre univers pour photographier la beauté de ces instants au naturel.</p>
          <p>Chez vous ou dans un lieu qui vous est cher, je m’attache à créer des images naturelles et sincères, où les sourires, les câlins et les émotions sont capturés tels qu’ils sont.</p>
          <p>Mon approche privilégie le confort et le bien-être de chacun, laissant place à la spontanéité et aux échanges pour des photos qui vous ressemblent. Pour immortaliser ces précieux souvenirs, je vous invite à me contacter pour réserver votre séance à domicile.</p>
          <p className='infos__text__question'>Pourquoi faire une séance photo ?</p>
          <p>Le temps file à toute allure, en créant de belles images avec moi, vous aurez des souvenirs authentiques pour la vie…</p>
          <p>Un sourire, un regard, un câlin, toutes ces petites attentions du quotidien seront figés à jamais.</p>

          <p className='infos__text__question'>Quels sont les tarifs ?</p>
          <li>10 photos : 200 €</li>
          <li>15 photos : 250 €</li>
          <li>20 photos : 300 €</li>

          <p className='infos__text__question'>Que comprend ce tarif ?</p>
          <ul>
            <li>La séance photo d’une durée d’environ 1h00 / 1h30.</li>
            <li>Les photos choisies sublimées en version numérique.</li>
            <li>Le déplacement à votre domicile ou sur le lieu de la séances pour les séance en extérieur.</li>
          </ul>
          <p> Je me déplace dans un rayon de 15 km autour de Cardet (30350). Pour toute demande au-delà de ce rayon, un supplément sera demandé. </p>
        </div>

      

        <img src={image2} alt='' className='infos__image'/>
    
    

      
        
          
    </div>
  );
};

Infos.propTypes = {
};


