import { connect } from 'react-redux';
import { ChangePassword } from '../../components/ChangePassword';
import { updateUsersPassword } from '../../store/actions/user';
import { clearError } from '../../store/actions/error';
import { setSuccessChangePw } from '../../store/actions/authentification';


const mapStateToProps = (state) => ({
  userId: state.auth.id,
  error: state.error.error,
  errorMessages: state.error.errorMessages,
  successChangePw: state.auth.successChangePw,
});

const mapDispatchToProps = (dispatch) => ({
  
  updateUsersPassword: (userId, oldPassword, newPassword) => {
    dispatch(updateUsersPassword(userId, oldPassword, newPassword));
  },
  clearError: () => {
    dispatch(clearError());
  },
  setSuccessChangePw: (value) => {
    dispatch(setSuccessChangePw(value));
  }
  
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);