import React from 'react';
// import PropTypes from 'prop-types';
import './styles.scss';
import image from './../../../assets/images/Laura/LauraAPN.jpeg';


export const AboutHome = () => {

  return (

    <div className="about-home">
      <img src={image} alt="laura huisman" className='about-home__image' />
      <div>
        <h2 className="about-home__title customTitle">Je suis heureuse de vous accueillir ici</h2>
        <p>Photographe depuis 2017, je suis spécialisée dans la maternité, la naissance et la famille.</p>
        <p>Étant moi-même maman, j’ai conscience de l’importance de capturer les jolis moments de vie.</p>
        <p>Je propose des séances chez vous, dans votre petit cocon, en extérieur ou dans un lieu qui vous tient à coeur.</p>
        <a className='myButton mt-3' href="/votre-photographe">En lire plus sur moi</a> 
      </div>
    </div>


  );
};

AboutHome.propTypes = {
};


