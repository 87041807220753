// Sidebar.js
import React from 'react';
import './styles.scss';

const Sidebar = ({ sections, activeSection, handleSectionClick }) => {
  return (
    <div className="sidebar">
      {sections.map((section) => (
        <div
          key={section.anchor}
          className={`sidebar__item ${activeSection === section.anchor ? 'sidebar__item__active' : ''}`}
          onClick={() => handleSectionClick(section.anchor)}
        >
          <div className="sidebar__item__dot"></div>
          {/* <div className="sidebar__item__label">{section.label}</div> */}
        </div>
      ))}
    </div>
  );
};

export default Sidebar;
