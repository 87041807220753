import { connect } from 'react-redux';
import { Favourites } from '../../components/Favourites';

import { setFavourite, validateFavourites } from '../../store/actions/shooting';


const mapStateToProps = (state, ownProps) => ({
    shooting: state.shooting.shooting,
    userId: state.auth.id,
    favouritesSelected: state.shooting.shooting.favouritesSelected,
    user: state.user,
    isLogged: state.auth.isLogged,
});

const mapDispatchToProps = (dispatch, ownProps) => ({

    setFavourite: (pictureId, shootingId) => {
        dispatch(setFavourite(pictureId, shootingId));
    },

    validateFavourites: (shootingId, userId) => {
        dispatch(validateFavourites(shootingId, userId));
    }
  
});


export default connect(mapStateToProps, mapDispatchToProps)(Favourites);
