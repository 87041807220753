import React from 'react';
import { Navigate } from 'react-router-dom';
// import PropTypes from 'prop-types';

import './styles.scss';
import ChangePassword from '../../containers/ChangePassword';

export const Account = ({
  user,
  isLogged
}) => {

  if(isLogged & user.firstConnect) {
    return <Navigate to="/temp" />;
  }

  return (
    <div className='account'>
      <div className='account__title'> Mes informations</div>
      <b> {user.firstName} {user.lastName} </b>
      <div> {user.email}</div>
      { user.isClient && 
        <>
        <div> {user.address} - {user.postalCode} {user.city}</div>
        <div> {user.phoneNumber} </div>
        </>
      }
      
      <ChangePassword />
      
    </div>

    
  );
};

Account.propTypes = {
};

