import React, { useState, useRef } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';

import { FirstPart } from './FirstPart';
import { Welcome } from './Welcome';
import { Pictures } from './Pictures';
//import { LongImageHome } from './LongImageHome';
//import { Footer } from '../../components/Footer';
import Sidebar from './Sidebar';

import './styles.scss';
import { AboutHome } from './AboutHome';

const SEL = "custom-section";
const SECTION_SEL = `.${SEL}`;

const Home = () => {
  const [activeSection, setActiveSection] = useState('home');
  const fullpageApiRef = useRef(null);

  const afterLoad = (origin, destination, direction) => {
    setActiveSection(destination.anchor);
  };

  const handleSectionClick = (anchor) => {
    if (fullpageApiRef.current) {
      fullpageApiRef.current.moveTo(anchor);
    }
  };

  const sections = [
    { anchor: "home", label: "Home" },
    { anchor: "bienvenue", label: "Bienvenue" },
    { anchor: "c-est-moi", label: "C'est moi" },
    { anchor: "photos", label: "Photos" }
  ];

  return (
    <div className={`home ${activeSection !== 'home' ? 'home__opacity' : ''}`}>
      <Sidebar sections={sections} activeSection={activeSection} handleSectionClick={handleSectionClick} />
      <ReactFullpage
        debug
        licenseKey={process.env.REACT_APP_FULLPAGE_JS_KEY}
        navigation={false}
        anchors={["home", "bienvenue", "c-est-moi", "photos"]}
        sectionSelector={SECTION_SEL}
        credits={false}
        afterLoad={afterLoad}
        render={({ fullpageApi }) => {
          fullpageApiRef.current = fullpageApi;
          return (
            <ReactFullpage.Wrapper>
              <div className={SEL}>
                <FirstPart />
              </div>
              <div className={SEL}>
                <Welcome />
              </div>
              <div className={SEL}>
                <AboutHome />
              </div>
              <div className={SEL}>
                <Pictures />
              </div>
            </ReactFullpage.Wrapper>
          );
        }}
      />
    </div>
  );
};

export default Home;
