import { connect } from 'react-redux';
import { ShootingShow } from '../../pages/Shooting/Show';
import { fetchShooting, updateShooting } from '../../store/actions/shooting';
import { deletePicture} from '../../store/actions/picture';


const mapStateToProps = (state, ownProps) => ({
    shooting: state.shooting.shooting,
    isLoading: state.shooting.isLoading,
    favouritesSelected: state.shooting.shooting.favouritesSelected,
    user: state.user,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    
    fetchShooting: (id) => {
        dispatch(fetchShooting(id));
    },
    
    updateShooting: (id, newNumberOfPictures) => {
        dispatch(updateShooting(id, newNumberOfPictures));
    },

    deletePicture: (pictureId, shootingId) => {
        dispatch(deletePicture(pictureId, shootingId));
    }
});


export default connect(mapStateToProps, mapDispatchToProps)(ShootingShow);
