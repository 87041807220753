import { connect } from 'react-redux';
import { Gallery } from '../../components/Gallery';

import { setFavourite, fetchShooting } from '../../store/actions/shooting';


const mapStateToProps = (state, ownProps) => ({
    shooting: state.shooting.shooting,
    favouritesSelected: state.shooting.shooting.favouritesSelected,
    user: state.user,
    isLogged: state.auth.isLogged,
});

const mapDispatchToProps = (dispatch, ownProps) => ({

    setFavourite: (pictureId, shootingId) => {
        dispatch(setFavourite(pictureId, shootingId));
    },

    fetchShooting: (shootingId) => {
        dispatch(fetchShooting(shootingId));
    },
  
});


export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
