import axios from 'axios';
import { logout } from '../../actions/authentification';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 2500,
  headers: {
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
  },
});


// Ajoutez un intercepteur de requête pour ajouter le token d'authentification à chaque requête
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem('refreshToken');
      if (refreshToken) {
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/refresh`, {refreshToken});
          const newAccessToken = response.data.token;
          localStorage.setItem('token', newAccessToken);  //set new token
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return axios(originalRequest); //recall Api with new token
        } catch (error) {
          // logout the user and re-authenticate by login again
          logout();
          window.location.reload();
        }
      }
    }
    return Promise.reject(error);
  }
);

export default api;




