import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import './styles.scss';

export const AddPicture = ({
  uploadImage,
}) => {

    let {id} = useParams();

    const [files, setFiles] = useState([]);
    const [progressBar, setProgressBar] = useState(0);

    const onChange = (e) => {
      setFiles(e.target.files);
    }

    var config = {
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
        setProgressBar(percentCompleted);
      }
    };
    
    const handleUpload = (e) => {
      e.preventDefault();
      
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
      }
    
      uploadImage(formData, id, config);
    };


  return (
  
    <div className='addPicture'>
      <form onSubmit={handleUpload} className='addPicture__form'>
          <input type="file" onChange={onChange} multiple />
          <button type='submit'>Ajouter</button>
      </form>

      {progressBar > 0 &&  progressBar < 100 &&
      <div className='progress'>
        <div className='progress-bar progress-bar-striped progress-bar-animated' role='progressbar' aria-label='progressbar' aria-valuenow={progressBar} aria-valuemin="0" aria-valuemax="100" style={{width: `${progressBar}%`}}> {progressBar} % </div>
      </div>
    }
    
    </div>
 
  );
};

AddPicture.propTypes = {
};