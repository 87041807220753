export const CLEAR_SUCCESS = 'CLEAR_SUCCESS';
export const SET_SUCCESS_MESSAGE = 'SET_SUCCESS_MESSAGE';

  
export const clearSuccess = () => ({
  type: CLEAR_SUCCESS
});

export const setSuccessMessage = (success) => ({  
  type: SET_SUCCESS_MESSAGE,
  success
});