import React from 'react';

import './styles.scss';
import image from './../../../assets/images/landscape/gili.jpg';


export const Welcome = () => {

  return (
    <div className='welcome'>
      <img src={image} alt='' className='welcome__left'/>

      <div className='welcome__right'>
        <h2 className='welcome__right__title customTitle'>Bienvenue</h2>
        <p className='welcome__right__text'>
          <i className="bi bi-chevron-double-left"></i> Chaque séance photo est une occasion de créer des images qui reflètent votre <b>individualité</b> et votre bonheur. Mon approche est empreinte de douceur, de patience et de bienveillance, afin de vous mettre à l’aise et de capturer des expressions naturelles et sincères. <i className="bi bi-chevron-double-right"></i>
        </p>
      </div>
    </div>
  );
};

Welcome.propTypes = {};
