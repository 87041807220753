import {
    SAVE_SHOOTINGS,
    SET_SHOOTING_ID,
    VALIDATE_FAVOURITES_MESSAGE,
    REFRESH_SHOOTING,
    SAVE_SHOOTING,
  } from '../actions/shooting';

  import { SET_IS_LOADING } from '../actions/picture';

  import {
    LOGOUT,
  } from '../actions/authentification';

  export const initialState = {
    shootings: [],
    isLoading: true,
    shooting: {},
  };

  const reducer = (state = initialState, action = {}) => {
    switch (action.type) {

      case SAVE_SHOOTINGS:
        return {
          ...state,
            shootings: action.shootings,
            isLoading: false,
        };

      case SET_SHOOTING_ID:
        return {
          ...state,
            shootingId: state.shootings.find(shooting => shooting.id === Number(action.shootingId).id),
        };

      case VALIDATE_FAVOURITES_MESSAGE:
        if (action.response === 200 ) {
          return {
            ...state,
            ['validateFavouritesMessage' + action.shootingId]: 'Votre demande a bien été envoyée à votre photographe, vous recevrez très vite un mail vous informant la possibilité de télécharger vos photos.',
            ['sendEmailWithFavourites' + action.shootingId]: true,
          };
        } else {
          return {
            ...state,
            ['validateFavouritesMessage' + action.shootingId]: 'Une erreur est survenue, merci de recommencer.',
            ['sendEmailWithFavourites' + action.shootingId]: false,
          };
        }

      case REFRESH_SHOOTING:
        return {
          ...state,
          isLoading: false,
          shootings: state.shootings.map(shooting => {
            if (shooting.id === action.shootingId) {
              return {
                ...shooting,
                pictures: shooting.pictures.filter(picture => picture.id !== Number(action.pictureId)),
              };
            }
            return shooting;
          }
        )};

        case SET_IS_LOADING:
          return {
            ...state,
            isLoading: true,
          };

        case SAVE_SHOOTING:
          return {
            ...state,
            isLoading: false,
            shooting: action.shooting,
          };


      case LOGOUT:
        return initialState;

      default:
        return state;
    }
  };

  export default reducer;
