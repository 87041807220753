import { connect } from 'react-redux';
import { ChangeTemporaryPassword } from '../../components/ChangeTemporaryPassword';

const mapStateToProps = (state) => ({
    firstConnect: state.user.firstConnect,
});

const mapDispatchToProps = (dispatch) => ({



});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeTemporaryPassword);
