export const LOGIN = 'LOGIN';
export const SIGNUP = 'SIGNUP';
export const SAVE_USER = 'SAVE_USER';
export const LOGOUT = 'LOGOUT';
export const SHOW_ERROR_LOGIN_MESSAGE = 'SHOW_ERROR_LOGIN_MESSAGE';
export const FORGOT_ERROR = 'FORGOT_ERROR';
export const FORGOT_SUCCESS = 'FORGOT_SUCCESS';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const SET_SUCCESS_CHANGE_PW = 'SET_SUCCESS_CHANGE_PW';


export const login = (email, password) => ({
    type: LOGIN,
    email,
    password,
  });

export const signup = (newClient) => ({
  newClient,
  type: 'SIGNUP'
});

export const saveUser = (payload) => ({
  type: SAVE_USER,
  payload,
});

export const logout = () => ({
  type: LOGOUT,
});

export const showErrorLoginMessage = (message) => ({
  type: 'SHOW_ERROR_LOGIN_MESSAGE',
  message,
});

export const createForgotErrorAction = () => ({ 
  type: FORGOT_ERROR 
});

export const createForgotAction = () => ({
  type: FORGOT_SUCCESS,
});

export const forgotPassword = (forgotEmail) => ({
  type: FORGOT_PASSWORD,
  forgotEmail,
});

export const setSuccessChangePw = () => ({
  type: 'SET_SUCCESS_CHANGE_PW',
});

