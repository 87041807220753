import React, { useState } from 'react';
import PhotoAlbum from 'react-photo-album';
import Lightbox from 'yet-another-react-lightbox';
import { Navigate } from 'react-router-dom';


import "yet-another-react-lightbox/styles.css";

import './styles.scss';
import Favourites from '../../containers/Favourites';

export const Gallery = ({
  gallery,
  layout,
  columns,
  setFavourite,
  deletePicture,
  shooting,
  favouritesSelected,
  //theme,
  user,
  isLogged,
}) => { 
 
  const [index, setIndex] = useState(-1);

  const breakpoints = [4320, 2160, 1080, 640, 384, 256, 128];

  if(!gallery) {
    return null;
  }

  if(isLogged & user.firstConnect) {
    return <Navigate to="/temp" />;
  }

  gallery.sort((a, b) => a.id - b.id);

  const photos = gallery.map((photo, index) => {
    const width = photo.width;
    const height = photo.height;
    const isFavourite = photo.isFavourite;
    return {
      src: `${photo.secureUrl}`,
      key: `${index}`,
      width,
      height,
      isFavourite,
      id: `${photo.id}`,
      images: breakpoints.map((breakpoint) => {
        const breakpointHeight = Math.round((height / width) * breakpoint);
        return {
          src: `${photo.secureUrl}`,
          width: breakpoint,
          height: breakpointHeight,
        };
      })
    };
  });

  /* if(theme) {
    photos.forEach((photo, index) => {
      if(Number(photo.id) === Number(theme.mainPictureId)) {
        photos.splice(index, 1);
      }
    });
  } */

  const slides = photos.map(({ src, key, width, height, images }) => ({
    src,
    key,
    aspectRatio: width / height,
    srcSet: images?.map((image) => ({
      src: image.src,
      width: image.width
    }))
  }));

  const renderPhoto = ({ 
    imageProps: { alt, style, ...restImageProps },
    photo
  }) => { 

    return (      
    
      <div style={{ width: style?.width}}>

        <img alt={alt} style={{ ...style, width: "100%", padding: 0 }} {...restImageProps} />
        
        { user.isClient && !favouritesSelected && (
          <div className='gallery__buttons'>
          { !photo.isFavourite && shooting && (
            <div className='gallery__buttons__button' onClick={handleSetFavourite} id={photo.id}>
              <i className="bi bi-plus-lg gallery__button__icon" id={photo.id}></i>
              <span className='gallery__buttons__button__legend' id={photo.id}> Ajouter aux favorites </span>
            </div>  
            )
          }
          </div>
        )} 

        { user.isClient && favouritesSelected && (
          <button className='myButton' onClick={() => handleDownloadImage(photo)}><i className="bi bi-download"></i></button>
        )}


        { !user.isClient && (
          <div className='gallery__buttons'>
              <div className='gallery__buttons__button' onClick={handleDeletePicture} id={photo.id}> 
                <i className="bi bi-x-lg gallery__buttons__button__action" id={photo.id}></i> 
                <span className='gallery__buttons__button__legend' id={photo.id}> Supprimer </span>
              </div>
          </div>
        )}
      </div>
      
  )};


  const handleSetFavourite = (evt) => {
    setFavourite(evt.target.id, shooting.id);
  }
  
  const handleDeletePicture = (evt) => {
    // demander confirmation avant de supprimer
    

    //TO DO : revoir vu qu'on enlève le thème
    //deletePicture(evt.target.id, shooting ? shooting.id : null, theme ? theme.id : null);
  }

  const handleDownloadImage = (image) => {
    fetch(image.src)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = shooting.nameOfGallery + '-' + image.key;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Erreur lors du téléchargement de l\'image : ', error);
      });
  };

  return (
  
      <div className='gallery'>
        <div className='gallery__container'>
          <div className='gallery__container__photoAlbum'>
            {!user.isClient && shooting && shooting.nameOfFavourites &&
              <div>
                <p> Cette séance photo a eu lieu il y a plus de 6 mois, les photos ont été supprimées. Voici le nom des photos sélectionnées : </p> 
                <p> {shooting.nameOfFavourites} </p>
              </div>
            }
            <PhotoAlbum
              layout={layout}
              columns={columns}
              photos={photos}
              targetRowHeight={150}
              onClick={(event, photo, index) => setIndex(index)}
              componentsProps={{ imageProps: { loading: "lazy" } }}
              renderPhoto={renderPhoto}
            />
          </div>

          <Favourites />

        </div>

        <Lightbox
          open={index >= 0}
          index={index}
          close={() => setIndex(-1)}
          slides={slides}
        />  
      </div>
  );
};

Gallery.propTypes = {
};
