import { connect } from 'react-redux';
import { App } from '../../components/App';


const mapStateToProps = (state, ownProps) => ({
  isLogged: state.auth.isLogged,
  //themes: state.theme.themes,
  shootings: state.shooting.shootings,
  user: state.user,
});

const mapDispatchToProps = (dispatch, ownProps) => ({

});


export default connect(mapStateToProps, mapDispatchToProps)(App);
