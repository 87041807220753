import {
    CLEAR_SUCCESS,
    SET_SUCCESS_MESSAGE,
  } from '../actions/success';

  import {
    LOGOUT,
  } from '../actions/authentification';

export const initialState = {
    success: null
};
  
const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case CLEAR_SUCCESS:
            return {
                ...state,
                success: null,
            };
        case SET_SUCCESS_MESSAGE:
            return {
                ...state,
                success: action.success,
            };
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export default reducer;