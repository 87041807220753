import { connect } from 'react-redux';
import { Account } from '../../pages/Account';

const mapStateToProps = (state) => ({
  user: state.user,
  isLogged: state.auth.isLogged,
});

const mapDispatchToProps = (dispatch) => ({
  
 
  
});

export default connect(mapStateToProps, mapDispatchToProps)(Account);