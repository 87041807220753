import { connect } from 'react-redux';
import { Clients } from '../../components/Clients';
import { signup } from '../../store/actions/authentification';
import { fetchShooting, deleteShooting, addNewShooting, setShootingId, fetchShootingsByUser } from '../../store/actions/shooting';
import { clearError } from '../../store/actions/error';
import { clearSuccess } from '../../store/actions/success';
import { fetchClientsOfPhotographer, updateUser } from '../../store/actions/user';


const mapStateToProps = (state) => ({
  shootings: state.shooting.shootings,
  clients: state.user.clients,
  //themes: state.theme.themes,
  error: state.error.error,
  success: state.success.success,
});

const mapDispatchToProps = (dispatch) => ({

  fetchShooting: (shootingId) => {
    dispatch(fetchShooting(shootingId))
  },

  deleteShooting: (shootingId) => {
    dispatch(deleteShooting(shootingId))
  },

  addNewUser: (newClient) => {
    dispatch(signup(newClient));
  },

  addNewShooting : (newShooting) => {
    dispatch(addNewShooting(newShooting));
  },
  
  setShootingId: (shootingId) => {
    dispatch(setShootingId(shootingId))
  },

  fetchClients: () => {
    const photographerId = localStorage.getItem('userId');
    dispatch(fetchClientsOfPhotographer(photographerId))
  },

  fetchShootingsByUser: () => {
    dispatch(fetchShootingsByUser())
  },

  closeError: () => {
    dispatch(clearError())
  },

  closeSuccess: () => {
    dispatch(clearSuccess())
  },

  updateUser(userId, data) {
    dispatch(updateUser(userId, data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Clients);
